import React from "react";
import { Redirect } from "react-router-dom";

// Pages Adherents
import AdherentsForm from "../pages/Adherents/Form/AdherentsForm";
import AdherentsList from "../pages/Adherents/List/AdherentsList";

// Authentication related pages
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

//  // Inner Authentication
// import EmailVerification from "../pages/AuthenticationInner/auth-email-verification";
// import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2";
// import LockScreen from "../pages/AuthenticationInner/auth-lock-screen";
// import LockScreen2 from "../pages/AuthenticationInner/auth-lock-screen-2";
// import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification";
// import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2";
// import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";
// import ForgetPwd2 from "../pages/AuthenticationInner/ForgetPassword2";
// import Login1 from "../pages/AuthenticationInner/Login";
// import Login2 from "../pages/AuthenticationInner/Login2";
// import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail";
// import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2";
// import Recoverpw from "../pages/AuthenticationInner/Recoverpw";
// import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2";
// import Register1 from "../pages/AuthenticationInner/Register";
// import Register2 from "../pages/AuthenticationInner/Register2";

//Pages
// import Pages404 from "../pages/Utility/pages-404";
// import Pages500 from "../pages/Utility/pages-500";
// import PagesComingsoon from "../pages/Utility/pages-comingsoon";
// import PagesMaintenance from "../pages/Utility/pages-maintenance";

//Contacts

//Activites
import ActivitesForm from "pages/Activites/Form/ActivitesForm";
import ActivitesList from "pages/Activites/Listing/ActivitesList";

// Utilisateurs
import ActivitesView from "pages/Activites/View/ActivitesView";
import AdherentsView from "pages/Adherents/View/AdherentsView";
import UtilisateursForm from "pages/Utilisateurs/Form/UtilisateursForm";
import UtilisateursList from "pages/Utilisateurs/List/UtilisateursList";
import UtilisateursView from "pages/Utilisateurs/View/UtilisateursView";
import FamillesView from "pages/Adherents/View/FamillesView";

const authProtectedRoutes = [
  // Adherents
  { path: ["/", "/adherents"], component: AdherentsList, exact: true },
  { path: "/adherents/new", component: AdherentsForm },
  { path: "/adherents/view/:id", component: AdherentsView },
  { path: "/adherents/edit/:id", component: AdherentsForm },
  { path: "/familles/view/:id", component: FamillesView },
  // Activites
  { path: "/activites", component: ActivitesList },
  { path: "/activites/new", component: ActivitesForm },
  { path: "/activites/view/:id", component: ActivitesView },
  { path: "/activites/edit/:id", component: ActivitesForm },
  // Utilisateurs
  { path: "/utilisateurs", component: UtilisateursList },
  { path: "/utilisateurs/new", component: UtilisateursForm },
  { path: "/utilisateurs/view/:id", component: UtilisateursView },
  { path: "/utilisateurs/edit/:id", component: UtilisateursForm },

  // { path: "/dashboard", component: Dashboard },
  // { path: "/dashboard-saas", component: DashboardSaas },
  // { path: "/dashboard-crypto", component: DashboardCrypto },
  // { path: "/blog", component: Blog },

  // //calendar
  // { path: "/calendar", component: Calendar },

  // //profile
  // { path: "/profile", component: UserProfile },

  //Invoices
  // { path: "/invoices-list", component: InvoicesList },
  // { path: "/invoices-detail/:id?", component: InvoiceDetail },

  // Tasks
  // { path: "/tasks-list", component: TasksList },
  // { path: "/tasks-kanban", component: TasksKanban },
  // { path: "/tasks-create", component: TasksCreate },

  //Projects
  // { path: "/projects-grid", component: ProjectsGrid },
  // { path: "/projects-list", component: ProjectsList },
  // { path: "/projects-overview", component: ProjectsOverview },
  // { path: "/projects-overview/:id", component: ProjectsOverview },
  // { path: "/projects-create", component: ProjectsCreate },

  //Blog
  // { path: "/blog-list", component: BlogList },
  // { path: "/blog-grid", component: BlogGrid },
  // { path: "/blog-details", component: BlogDetails },

  // Contacts
  // { path: "/contacts-grid", component: ContactsGrid },
  // { path: "/contacts-list", component: ContactsList },
  // { path: "/contacts-profile", component: ContactsProfile },

  //Charts
  // { path: "/apex-charts", component: ChartApex },
  // { path: "/chartist-charts", component: ChartistChart },
  // { path: "/chartjs-charts", component: ChartjsChart },
  // { path: "/e-charts", component: EChart },
  // { path: "/sparkline-charts", component: SparklineChart },
  // { path: "/charts-knob", component: ChartsKnob },
  // { path: "/re-charts", component: ReCharts },

  // Icons
  // { path: "/icons-boxicons", component: IconBoxicons },
  // { path: "/icons-dripicons", component: IconDripicons },
  // { path: "/icons-materialdesign", component: IconMaterialdesign },
  // { path: "/icons-fontawesome", component: IconFontawesome },

  // Tables
  // { path: "/tables-basic", component: BasicTables },
  // { path: "/tables-datatable", component: DatatableTables },
  // { path: "/tables-responsive", component: ResponsiveTables },
  // { path: "/tables-editable", component: EditableTables },
  // { path: "/tables-dragndrop", component: DragDropTables },

  // Maps
  // { path: "/maps-google", component: MapsGoogle },
  // { path: "/maps-vector", component: MapsVector },
  // { path: "/maps-leaflet", component: MapsLeaflet },

  // Forms
  // { path: "/form-elements", component: FormElements },
  // { path: "/form-layouts", component: FormLayouts },
  // { path: "/form-advanced", component: FormAdvanced },
  // { path: "/form-editors", component: FormEditors },
  // { path: "/form-mask", component: FormMask },
  // { path: "/form-repeater", component: FormRepeater },
  // { path: "/form-uploads", component: FormUpload },
  // { path: "/form-wizard", component: FormWizard },
  // { path: "/form-validation", component: FormValidations },
  // { path: "/form-xeditable", component: FormXeditable },
  // { path: "/dual-listbox", component: DualListbox },

  // Ui
  // { path: "/ui-alerts", component: UiAlert },
  // { path: "/ui-buttons", component: UiButtons },
  // { path: "/ui-cards", component: UiCards },
  // { path: "/ui-carousel", component: UiCarousel },
  // { path: "/ui-colors", component: UiColors },
  // { path: "/ui-dropdowns", component: UiDropdown },
  // { path: "/ui-general", component: UiGeneral },
  // { path: "/ui-grid", component: UiGrid },
  // { path: "/ui-images", component: UiImages },
  // { path: "/ui-lightbox", component: UiLightbox },
  // { path: "/ui-modals", component: UiModal },
  // { path: "/ui-progressbars", component: UiProgressbar },
  // { path: "/ui-sweet-alert", component: UiSweetAlert },
  // { path: "/ui-tabs-accordions", component: UiTabsAccordions },
  // { path: "/ui-typography", component: UiTypography },
  // { path: "/ui-video", component: UiVideo },
  // { path: "/ui-session-timeout", component: UiSessionTimeout },
  // { path: "/ui-rating", component: UiRating },
  // { path: "/ui-rangeslider", component: UiRangeSlider },
  // { path: "/ui-notifications", component: UiNotifications },
  // { path: "/ui-offcanvas", component: UiOffCanvas },
  // { path: "/ui-breadcrumb", component: UiBreadcrumb },
  // { path: "/ui-placeholders", component: UiPlaceholders },
  // { path: "/ui-toasts", component: UiToasts },

  //Utility
  // { path: "/pages-starter", component: PagesStarter },
  // { path: "/pages-timeline", component: PagesTimeline },
  // { path: "/pages-faqs", component: PagesFaqs },
  // { path: "/pages-pricing", component: PagesPricing },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  // { path: "/", exact: true, component: () => <Redirect to="/adherents" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },

  // { path: "/pages-maintenance", component: PagesMaintenance },
  // { path: "/pages-comingsoon", component: PagesComingsoon },
  // { path: "/pages-404", component: Pages404 },
  // { path: "/pages-500", component: Pages500 },
  // { path: "/crypto-ico-landing", component: CryptoIcoLanding },

  // Authentication Inner
  // { path: "/pages-login", component: Login1 },
  // { path: "/pages-login-2", component: Login2 },
  // { path: "/pages-register", component: Register1 },
  // { path: "/pages-register-2", component: Register2 },
  // { path: "/page-recoverpw", component: Recoverpw },
  // { path: "/page-recoverpw-2", component: Recoverpw2 },
  // { path: "/pages-forgot-pwd", component: ForgetPwd1 },
  // { path: "/auth-recoverpw-2", component: ForgetPwd2 },
  // { path: "/auth-lock-screen", component: LockScreen },
  // { path: "/auth-lock-screen-2", component: LockScreen2 },
  // { path: "/page-confirm-mail", component: ConfirmMail },
  // { path: "/page-confirm-mail-2", component: ConfirmMail2 },
  // { path: "/auth-email-verification", component: EmailVerification },
  // { path: "/auth-email-verification-2", component: EmailVerification2 },
  // { path: "/auth-two-step-verification", component: TwostepVerification },
  // { path: "/auth-two-step-verification-2", component: TwostepVerification2 },
];

export { authProtectedRoutes, publicRoutes };
